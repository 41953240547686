import React, {useContext, useState} from 'react';
import cl from './styles/Main.module.scss'
import {AuthContext} from "../context";
import QuestionnaireList from "../UI/QuestionnaireList";
import RoomList from "../UI/RoomList";
import Hello from "../UI/Hello";

const Main = () => {
    const {user} = useContext(AuthContext);
    const [mayChat, setMayChat] = useState(false);

    return (
        <div className={cl.FullPage}>
            <div className={cl.Card}>
                {
                    !user ? <Hello/> : mayChat ? <RoomList/> :
                        <QuestionnaireList is_need_after_registration={true} setMayChat={setMayChat} />
                }
            </div>
        </div>
    );
};

export default Main;