import {useContext, useEffect, useRef, useState} from "react";
import useChatTimers from "./useChatTimers";
import Consts from "../services/Consts";
import useWebSocket, {ReadyState} from "react-use-websocket";
import Moment from "moment";
import {AsyncContext} from "../context";
import addNotification from "react-push-notification";

const useChat = (user, room, request) => {
    const [messages, setMessages] = useState([]);
    const scrollRef = useRef(null);
    const updateScroll = () => {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    };
    const {isLoading} = useContext(AsyncContext);
    const [systemMessage, enabled, setEnabled] = useChatTimers(room);
    const [socketUrl, setSocketUrl] = useState('wss://echo.websocket.org');
    const {sendMessage, lastMessage, readyState} = useWebSocket(socketUrl, {shouldReconnect: (event) => true});
    const [webSocketMessage, setWebSocketMessage] = useState('');
    const systemUser = {
        username: 'system',
        last_name: 'Система',
        first_name: ''
    };

    useEffect(() => {
        if (lastMessage !== null) {
            let messageData = JSON.parse(lastMessage.data);
            setMessages([...messages, messageData]);
            if (messageData.author.username !== user.username)
                addNotification({
                    title: `Новое сообщение от ${messageData.author.last_name} ${messageData.author.first_name}`,
                    message: messageData.content,
                    native: true // when using native, your OS will handle theming.
                });
        }
    }, [lastMessage]);

    useEffect(() => {
        const message =
            {
                [ReadyState.CONNECTING]: {color: 'warning', message: 'Идет подключение...'},
                [ReadyState.OPEN]: null,
                [ReadyState.CLOSING]: {color: 'warning', message: 'Идет отключение...'},
                [ReadyState.CLOSED]: {color: 'danger', message: 'Соединение разорвано. Повторная попытка...'},
                [ReadyState.UNINSTANTIATED]: {color: 'danger', message: 'Соединение разорвано. Повторная попытка...'},
            }[readyState];
        if (!message) {
            setWebSocketMessage({color: 'success', message: 'Подключение установлено.'});
            setTimeout(() => {
                setWebSocketMessage(null);
            }, 2500);
        } else
            setWebSocketMessage(message);

        if (room) {
            const timeToStart = Date.parse(room.start) - Date.now();
            const timeToFinish = Date.parse(room.finish) - Date.now();

            setEnabled(timeToStart < 0 && timeToFinish > 0 && !message);
        } else
            setEnabled(!message);
    }, [readyState]);

    useEffect(() => {
        if (room) {
            setSocketUrl(`wss://${Consts.api_url}/ws/${room.id}/${user.token}`);
            request(
                'get',
                `room/${room.id}/messages`,
                {token: user.token},
                (response) => {
                    if (response.status === 200)
                        setMessages([...response.data, ...messages]);
                }
            );
            updateScroll();
        }
    }, [room]);

    useEffect(() => {
        if (!isLoading && systemMessage !== '' && !messages.some((message) => {
            return message.author.username === systemUser.username && message.content === systemMessage;
        }))
            setMessages([...messages, {
                author: systemUser,
                content: systemMessage,
                time: Moment().toISOString()
            }]);
    }, [messages, systemMessage, isLoading]);

    useEffect(() => {
        updateScroll();
    }, [messages]);

    const sendMessageWrapper = (message) => {
        sendMessage(JSON.stringify({
            author: user,
            content: message,
            time: Date.now()
        }));
    }

    return [messages, sendMessageWrapper, scrollRef, enabled, webSocketMessage];
};

export default useChat;