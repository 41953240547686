import React, {useContext, useEffect, useState} from 'react';
import {AsyncContext, AuthContext} from "../context";
import TextQuestion from "./TextQuestion";
import ChoiceQuestion from "./ChoiceQuestion";

const QuestionList = ({questionnaire, setAnswer, ...props}) => {
    const {user} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (user && questionnaire && questionnaire.id) {
            request(
                'get',
                `questionnaire/${questionnaire.id}/questions/`,
                {token: user.token},
                (response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        data.map((question) => {
                            setAnswer(question, '');
                        });
                        setList(data);
                    }
                }
            );
        }
    }, [user, questionnaire]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ul {...props} className='list-group list-group-flush'>
            {list.map((question) => {
                if (question.resourcetype === 'TextQuestion')
                    return (<TextQuestion question={question} setAnswer={setAnswer} key={question.id}/>);
                if (question.resourcetype === 'ChoiceQuestion')
                    return (<ChoiceQuestion question={question} setAnswer={setAnswer} key={question.id}/>);
                return '';
            })}
        </ul>
    );
};

export default QuestionList;