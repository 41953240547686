import React from 'react';
import Button from "./Button";
import Moment from "moment";
import {Link} from "react-router-dom";
import Icon from "./Icon";

const RoomItem = ({room, ...props}) => {
    const start = Moment(room.start).format("DD.MM.YYYY HH:mm");
    const finish = Moment(room.finish).format("DD.MM.YYYY HH:mm");

    return (
        <div className='card mt-2'>
            <div className='card-header header-secondary d-flex flex-row justify-content-between align-items-center'>
                <span>{room.name}</span>
                <Link to={`room/${room.id}`}>
                    <Button color='primary'>
                        <Icon name='chat-right-dots' className='me-2'/>
                        Войти
                    </Button>
                </Link>
            </div>
            <div className='card-body'>
                {room.description}
            </div>
            <div className='card-footer'>
                {
                    Moment.now() < Moment(room.start)
                        ? <div className='text-muted'>Начнется: {start}</div>
                        : Moment.now() < Moment(room.finish)
                            ? <div className='text-muted'>Завершится: {finish}</div>
                            : <div className='text-muted'>Завершилось: {finish}</div>
                }
            </div>
        </div>
    );
};

export default RoomItem;