import React, {useState} from 'react';
import Button from "./Button";
import FormTextInput from "./FormTextInput";

const LoginForm = ({callback, setIdentificationMode, ...props}) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const login = (event) => {
        event.preventDefault();
        const userData = {
            'email': email,
            'password': password
        }
        callback(userData);
    }

    return (
        <div>
            <form onSubmit={login}>
                <div className='d-flex flex-column align-content-center justify-content-center'>
                    <FormTextInput label='E-Mail' type='email' value={email} set={setEmail} name='email'/>
                    <FormTextInput label='Пароль' type='password' value={password} set={setPassword} name='password'>
                        <a href='#' className='ms-2' onClick={(event) => {
                            event.preventDefault();
                            setIdentificationMode('forgot');
                        }}>
                            Забыли пароль?
                        </a>
                    </FormTextInput>
                    <div className='form-group d-flex flex-row justify-content-center mt-2'>
                        <Button color='primary' className='flex-grow-1'>
                            Войти
                        </Button>
                    </div>
                    <div className='form-group d-flex flex-row justify-content-center align-items-center mt-2'>
                        <span>
                            Нет аккаунта?
                            <a href='#' className='ms-2' onClick={(event) => {
                                event.preventDefault();
                                setIdentificationMode('register');
                            }}>
                                Пройдите регистрацию
                            </a>
                        </span>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default LoginForm;