import React, {useContext, useEffect, useState} from 'react';
import {AsyncContext, AuthContext} from "../context";
import RoomItem from "./RoomItem";

const RoomList = ({...props}) => {
    const {user} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (user)
            request(
                'get',
                'room/',
                {token: user.token},
                (response) => {
                    if (response.status === 200)
                        setList(response.data)
                }
            );
    }, [user]);

    return (
        <div {...props}>
            {
                list.map((room) => {
                    return (
                        <RoomItem room={room} key={room.id}/>
                    );
                })
            }
        </div>
    );
};

export default RoomList;