import {React, useContext, useEffect, useState} from 'react';
import Button from "./Button";
import Modal from "./Modal";
import Icon from "./Icon";
import LoginForm from "./LoginForm";
import {AsyncContext, AuthContext, IdentificationContext} from "../context";

const Login = () => {
    const setUser = useContext(AuthContext).setUser;
    const [isModalShown, setIsModalShown] = useState(false);
    const [errors, setErrors] = useState();
    const {request} = useContext(AsyncContext);
    const {identificationMode, setIdentificationMode} = useContext(IdentificationContext);

    useEffect(() => {
        setIsModalShown(identificationMode === 'login');
    }, [identificationMode]);

    const login = (userData) => {
        setIdentificationMode('');
        if (userData !== null)
            request('post', 'login/', userData, loginCallback);
    }

    const loginCallback = (response) => {
        if (response.status === 200) {
            localStorage.setItem('token', response.data.user.token);
            setUser(response.data.user);
        } else {
            setIdentificationMode('login');
            setErrors(response.data.errors);
        }
    }

    return (
        <div className='d-inline'>
            <Button color='primary' className='mx-2' onClick={() => setIdentificationMode('login')}>
                <Icon name='box-arrow-in-right' className='me-2'/>
                <span>Войти</span>
            </Button>
            <Modal
                id='login'
                title='Войти'
                isShown={isModalShown}
                setIsShown={() => login(null)}
                errors={errors}
            >
                <LoginForm callback={login} setIdentificationMode={setIdentificationMode}/>
            </Modal>
        </div>
    );
};

export default Login;