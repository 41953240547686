import React, {useContext, useEffect, useState} from 'react';
import Button from "./Button";
import Icon from "./Icon";
import Modal from "./Modal";
import LoginForm from "./LoginForm";
import {AsyncContext, AuthContext, IdentificationContext} from "../context";
import ForgotForm from "./ForgotForm";

const Forgot = () => {
    const setUser = useContext(AuthContext).setUser;
    const [isModalShown, setIsModalShown] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errors, setErrors] = useState();
    const {request} = useContext(AsyncContext);
    const {identificationMode, setIdentificationMode} = useContext(IdentificationContext);

    useEffect(() => {
        setIsModalShown(identificationMode === 'forgot');
    }, [identificationMode]);

    const forgot = (userData) => {
        setIdentificationMode('');
        if (userData !== null)
            request('post', 'forgot/', userData, forgotCallback);

    };

    const forgotCallback = (response) => {
        if (response.status === 200) {
            setIsSuccess(true);
        } else {
            setIdentificationMode('forgot');
            setErrors(response.data.errors);
        }
    };

    return (
        <div className='d-inline'>
            <Modal
                id='login'
                title='Восстановление пароля'
                isShown={isModalShown}
                setIsShown={() => forgot(null)}
                errors={errors}
            >
                <ForgotForm callback={forgot} setIdentificationMode={setIdentificationMode}/>
            </Modal>
            {
                !isSuccess ? '' :
                    <Modal
                        title='Восстановление пароля'
                        isShown={isSuccess}
                        setIsShown={setIsSuccess}
                    >
                        <div className='text-center'>
                            <h3 className='text-success'>
                                Для продолжения восстановления пароля пройдите по ссылке отправленной Вам на электронную
                                почту.
                            </h3>
                            <Button className='btn btn-success px-5' onClick={() => setIsSuccess(false)}>
                                Ок
                            </Button>
                        </div>
                    </Modal>
            }
        </div>
    );
};

export default Forgot;