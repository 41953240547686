import React, {useState} from 'react';
import FormTextInput from "./FormTextInput";
import Button from "./Button";

const ForgotForm = ({callback, setIdentificationMode, ...props}) => {
    const [email, setEmail] = useState('');

    const send = (event) => {
        event.preventDefault();
        callback({email});
    };

    return (
        <div>
            <form onSubmit={send}>
                <div className='d-flex flex-column align-content-center justify-content-center'>
                    <FormTextInput label='E-Mail' type='email' value={email} set={setEmail} name='email'/>
                    <div className='form-group d-flex flex-row justify-content-center mt-2'>
                        <Button color='primary' className='flex-grow-1'>
                            Отправить
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ForgotForm;