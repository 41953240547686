import React, {useContext, useEffect, useState} from 'react';
import {AsyncContext, AuthContext} from "../context";
import QuestionnaireItem from "./QuestionnaireItem";

const QuestionnaireList = ({room = null, setMayChat, className, ...props}) => {
    const {request} = useContext(AsyncContext);
    const {user} = useContext(AuthContext);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (user) {
            let data = {};
            if (room) {
                data.room = room.id;
                data.at_enter = 1;
            } else if (user.need_answer_questionnaire)
                data.is_need_after_registration = 1;
            else
                data.at_exit = 1;
            request(
                'get',
                'questionnaire/',
                {
                    token: user.token,
                    ...data
                },
                (response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        setList(data);
                        setMayChat(data.length === 0);
                    }
                }
            );
        }
    }, [user]);

    return (
        <div className={`card ${className} ${list.length ? '' : 'd-none'}`}>
            <div className='card-header header-secondary'>
                Для продолжения работы в системе, требуется заполнить следующие анкеты:
            </div>
            <ul className='list-group list-group-flush'>
                {list.map((item) => {
                    return (<QuestionnaireItem key={item.id} item={item}/>)
                })}
            </ul>
        </div>
    );
};

export default QuestionnaireList;