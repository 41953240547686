import Main from "./pages/Main";
import RegistrationContinue from "./pages/RegistrationContinue";
import QuestionnaireDetail from "./pages/QuestionnaireDetail";
import RoomDetail from "./pages/RoomDetail";
import ForgotContinue from "./pages/ForgotContinue";

const routes = [
    {path: '/', element: <Main/>, exact: true},
    {path: '/registration/continue', element: <RegistrationContinue/>, exact: true},
    {path: '/questionnaire/:id', element: <QuestionnaireDetail/>, exact: true},
    {path: '/room/:id', element: <RoomDetail/>, exact: true},
    {path: '/forgot/continue', element: <ForgotContinue/>, exact: true}
];

export default routes;