import React from 'react';
import {Route, Routes} from "react-router-dom";
import routes from "../routes";
import cl from "./styles/AppRouter.module.scss"

const AppRouter = () => {
    return (
        <div className={cl.Content}>
            <Routes>
                {routes.map((route) => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={route.element}
                            exact={route.exact}
                        />
                    );
                })};
            </Routes>
        </div>
    );
};

export default AppRouter;