import React, {useContext, useState} from 'react';
import 'bootstrap/js/dist/modal'
import Login from "./Login";
import {AuthContext, IdentificationContext} from "../context";
import Logout from "./Logout";
import Register from "./Register";
import Consts from "../services/Consts";
import Icon from "./Icon";
import {Link} from "react-router-dom";
import cl from "./styles/Navbar.module.scss"
import Forgot from "./Forgot";

const Navbar = (...props) => {
    const {user, isAuthProceed} = useContext(AuthContext);
    const [identificationMode, setIdentificationMode] = useState('');

    return (
        <div className={`navbar bg-secondary ${cl.Navbar}`}>
            <Link to='/' className='text-decoration-none'>
                <h2 className='text-white ms-2 mb-0'>Хотелка</h2>
            </Link>
            {
                isAuthProceed || user
                    ?
                    <div>
                        {
                            !user || !user.is_staff ? '' :
                                <a href={`//${Consts.api_url}/admin`} target='_blank' className='btn btn-primary text-white me-2'>
                                    <Icon name="gear"/>
                                </a>
                        }
                        <Logout/>
                    </div>
                    :
                    <div>
                        <IdentificationContext.Provider value={{identificationMode, setIdentificationMode}}>
                            <Login/>
                            <Register/>
                            <Forgot/>
                        </IdentificationContext.Provider>
                    </div>
            }
        </div>
    );
};

export default Navbar;