import './main.scss';
import Navbar from "./UI/Navbar";
import {AuthContext, AsyncContext} from "./context";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./UI/AppRouter";
import useAuth from "./hooks/useAuth";
import useRequest from "./hooks/useRequest";
import Loader from "./UI/Loader";
import {Notifications} from "react-push-notification";

function App() {
    const [isLoading, request] = useRequest();
    const [user, setUser, isAuthProceed] = useAuth(request);

    return (
        <AsyncContext.Provider value={{isLoading, request}}>
            <AuthContext.Provider value={{user, setUser, isAuthProceed}}>
                <Notifications/>
                <BrowserRouter>
                    <Loader/>
                    <Navbar/>
                    <AppRouter/>
                </BrowserRouter>
            </AuthContext.Provider>
        </AsyncContext.Provider>
    );
}

export default App;
