import React, {useContext} from 'react';
import Icon from "./Icon";
import Button from "./Button";
import {AuthContext} from "../context";

const Logout = () => {
    const setUser = useContext(AuthContext).setUser;

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    }

    return (
        <Button color='primary' className='mx-2' onClick={logout}>
            <Icon name='box-arrow-in-right' className='me-2'/>
            <span>Выйти</span>
        </Button>
    );
};

export default Logout;