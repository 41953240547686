import React from 'react';
import cl from './styles/Chat.module.scss';
import Message from "./Message";
import FormTextInput from "./FormTextInput";
import Button from "./Button";
import Icon from "./Icon";
import {useEffect, useState} from "react";

const Chat = ({name, messages, scrollRef, sendMessage, enabled, webSocketMessage, className = '', ...props}) => {
    const [message, setMessage] = useState('Общение еще не началось');

    const onSubmit = (event) => {
        event.preventDefault();
        if (message) {
            sendMessage(message);
            setMessage('');
        }
    }

    useEffect(() => {
        if (enabled)
            setMessage('');
        else
            setMessage('Общение еще не началось');
    }, [enabled]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`card ${cl.Chat} ${className}`}>
            <div className='card-header header-secondary'>
                {name}
            </div>
            {
                !webSocketMessage ? '' :
                    <div className={`card-body m-0 rounded-0 alert alert-${webSocketMessage.color} ${cl.Alert}`}>
                        {webSocketMessage.message}
                    </div>
            }
            <div className={`card-body ${cl.Body}`}>
                <div className={cl.ScrollWrapper}>
                    <div className={cl.Scroll} ref={scrollRef}>
                        <div className={`${cl.MessageList}`}>
                            {messages.map((item, index) => {
                                return (
                                    <Message
                                        key={index}
                                        message={item}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
                <form onSubmit={onSubmit}>
                    <div className='d-flex p-3'>
                        <FormTextInput
                            name='message'
                            value={message}
                            set={setMessage}
                            type='text'
                            disabled={!enabled}
                            className='flex-grow-1'
                        />
                        <Button color='primary' className='ms-2' disabled={!enabled}>
                            <Icon name='send-fill'/>
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Chat;