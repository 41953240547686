import React from 'react';

const Button = ({children, color, className = '', disabled=false, ...props}) => {
    return (
        <button className={`btn btn-${color} ${className}`} {...props} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;