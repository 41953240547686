import React from 'react';

const FormTextArea = ({label, value, set, name, ...props}) => {
    return (
        <div className='form-group'>
            <label className='form-label'>{label}</label>
            <textarea
                className='form-control'
                name={name}
                value={value}
                onChange={(event) => set(event.currentTarget.value)}
            >
            </textarea>
        </div>
    );
};

export default FormTextArea;