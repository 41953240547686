import React from 'react';

const FormTextInput = ({children, label, type, value, set, name, disabled = false, ...props}) => {
    return (
        <div className='form-group' {...props}>
            {label ? <label className='form-label'>{label}</label> : ''}
            <input
                className='form-control'
                type={type}
                value={value || ''}
                name={name}
                disabled={disabled}
                onChange={(event) => set(event.currentTarget.value)}
            />
            {!children ? '' :
                <small className='form-text text-muted'>{children}</small>
            }
        </div>
    );
};

export default FormTextInput;