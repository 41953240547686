import React, {useContext} from 'react';
import cl from "./styles/Loader.module.scss";
import {AsyncContext} from "../context";

const Loader = () => {
    const {isLoading} = useContext(AsyncContext);

    return (
        <div className={`${cl.Loader} ${isLoading ? cl.Loading : ''}`}>
            <div className={cl.Spinner}>

            </div>
        </div>
    );
};

export default Loader;