import React, {useContext} from 'react';
import cl from './styles/Message.module.scss';
import {AuthContext} from "../context";
import Moment from "moment";

const Message = ({message, ...props}) => {
    const {user} = useContext(AuthContext);

    return (
        <div className={`${cl.MessageWrapper} ${user && message.author.username === user.username ? cl.Right : cl.Left}`}>
            <div
                className={cl.Message} {...props}>
                <div className={cl.Author}>
                    {message.author.last_name} {message.author.first_name}
                </div>
                <div className={cl.Body}>
                    <div className={cl.Content}>
                        {message.content}
                    </div>
                    <div className={cl.Time}>
                        {Moment(message.time).format('HH:mm')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Message;