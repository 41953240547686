import {useEffect, useState} from "react";

const useAuth = (request) => {
    const [user, setUser] = useState(null);
    const [isAuthProceed, setIsAuthProceed] = useState(true);

    const login_by_token = () => {
        setIsAuthProceed(true);
        request('post', 'login/', {token: localStorage.getItem('token')}, (response) => {
            if (response.status === 200)
                setUser(response.data.user);
            setIsAuthProceed(false);
        });
    };

    useEffect(() => {
        if (user) {
            const token_expires = Date.parse(user.token_expires) - Date.now();
            localStorage.setItem('token', user.token);
            setTimeout(() => {
                login_by_token();
            }, token_expires);
        }
    }, [user]);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            login_by_token();
        } else {
            if (!(['/', '/registration/continue', '/forgot/continue'].includes(document.location.pathname)))
                document.location.pathname = '/';
            setIsAuthProceed(false);
        }
    }, []);

    return [user, setUser, isAuthProceed];
};

export default useAuth;