import React from 'react';

const Hello = () => {
    return (
        <div className={`card mt-2`}>
            <div className='card-header header-secondary'>
                Здравствуйте!
            </div>
            <div className='card-body'>
                Для начала работы требуется авторизоваться.
            </div>
        </div>
    );
};

export default Hello;