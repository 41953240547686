import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import FormTextInput from "../UI/FormTextInput";
import cl from './styles/RegistrationContinue.module.scss'
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import useQueryParams from "../hooks/useQueryParams";
import {AsyncContext} from "../context";

const RegistrationContinue = () => {
    const [operation, key] = useQueryParams(['operation', 'key']);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const {request} = useContext(AsyncContext);
    const navigate = useNavigate();

    const registrationContinue = (event) => {
        event.preventDefault();
        if (password !== passwordConfirm) {
            setErrors({confirm: 'Пароли не совпадают!'})
            return false;
        }
        setErrors({});
        request('post', 'registration/continue/', {operation, key, password}, registrationContinueCallback);
    };

    const registrationContinueCallback = (response) => {
        if (response.status === 200) {
            setIsSuccess(true);
            setErrors({});
        } else {
            setErrors(response.data.errors);
        }
    };

    if (operation === 'cancel')
        request('post', 'registration/continue/', {operation, key}, registrationContinueCallback);

    const cancel = (event) => {
        navigate('/');
    }

    const success = () => {
        setIsSuccess(false);
        navigate('/');
    }

    return (
        <div className={cl.Wrapper}>
            <div className={`card ${cl.Form}`}>
                <div className='card-header'>
                    Завершение регистрации
                </div>
                {
                    !errors ? '' :
                        Object.entries(errors).map(([k, v]) => {
                            return (<div className='card-body alert alert-danger rounded-0' key={k}>{v}</div>);
                        })
                }
                <div className='card-body'>
                    <form>
                        <FormTextInput
                            label='Пароль'
                            type='password'
                            value={password}
                            set={setPassword}
                            name='password'
                        />
                        <FormTextInput
                            label='Подтверждение пароля'
                            type='password'
                            value={passwordConfirm}
                            set={setPasswordConfirm}
                            name='confirm'
                        />
                        <div className='form-group mt-2 d-flex flex-row justify-content-center'>
                            <Button color='primary' className='px-5' onClick={registrationContinue}>
                                Регистрация
                            </Button>
                            <Button color='secondary' className='px-5 ms-2' onClick={cancel}>
                                Отмена
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            {
                !isSuccess ? '' :
                    <Modal
                        title='Регистрация'
                        isShown={isSuccess}
                        setIsShown={success}
                    >
                        <div className='text-center'>
                            <h3 className='text-success'>
                                {
                                    operation === 'confirm'
                                        ? 'Регистрация завершена. Теперь Вы можете авторизоваться.'
                                        : 'Регистрация отменена.'
                                }
                            </h3>
                            <Button className='btn btn-success px-5' onClick={success}>
                                Ок
                            </Button>
                        </div>
                    </Modal>
            }
        </div>
    );
};

export default RegistrationContinue;
