import React from 'react';

const FormChoiceOne = ({label, variants, value, set, name, ...props}) => {
    return (
        <div className='form-group'>
            <label className='form-label'>{label}</label>
            {variants.map((variant) => {
                return (
                    <div className='form-check' key={variant.id}>
                        <input
                            type='radio'
                            name={name}
                            id={`variant_${variant.id}`}
                            className='form-check-input'
                            onChange={() => set(variant.text)}
                        />
                        <label
                            htmlFor={`variant_${variant.id}`}
                            className='form-check-label'
                        >
                            {variant.text}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default FormChoiceOne;