import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {AsyncContext, AuthContext} from "../context";
import cl from './styles/Room.module.scss'
import useChat from "../hooks/useChat";
import Chat from "../UI/Chat";

const RoomDetail = () => {
    const {id} = useParams();
    const {user} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);
    const navigate = useNavigate();
    const [room, setRoom] = useState(null);
    const [messages, sendMessage, scrollRef, enabled, webSocketMessage] = useChat(user, room, request);

    useEffect(() => {
        if (user && !room)
            request(
                'get',
                `room/${id}`,
                {token: user.token},
                (response) => {
                    if (response.status === 200)
                        setRoom(response.data);
                }
            );
    }, [user]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (room && room.need_answers_at_enter)
            navigate(`/questionnaire/${room.enter_questionnaire_id}`);
    }, [room]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={cl.FullPage}>
            <Chat
                name={room ? room.name : ''}
                messages={messages}
                scrollRef={scrollRef}
                sendMessage={sendMessage}
                enabled={enabled}
                webSocketMessage={webSocketMessage}
                className={cl.Card}
            />
        </div>
    );
};

export default RoomDetail;