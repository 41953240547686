import React, {useEffect, useState} from 'react';
import FormTextInput from "./FormTextInput";
import FormTextArea from "./FormTextArea";

const TextQuestion = ({question, setAnswer, ...props}) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        setAnswer(question, value);
    }, [value]);

    return (
        <li className='list-group-item'>
            {
                question.is_inline
                    ? <FormTextInput
                        label={question.text}
                        type='text'
                        value={value}
                        set={setValue}
                        name={`question_${question.id}`}
                    />
                    : <FormTextArea
                        label={question.text}
                        value={value}
                        set={setValue}
                        name={`question_${question.id}`}
                    />
            }
        </li>
    );
};

export default TextQuestion;