import React from 'react';
import Button from "./Button";
import Icon from "./Icon";
import cl from "./styles/QuestionnaireItem.module.scss"
import {Link} from "react-router-dom";

const QuestionnaireItem = ({item, ...props}) => {
    return (
        <li className={`list-group-item ${cl.Item}`} {...props}>
            <span>{item.name}</span>
            <Link to={`/questionnaire/${item.id}`}>
                <Button color='primary'>
                    <Icon name='pencil-square me-2'/>
                    <span>Пройти</span>
                </Button>
            </Link>
        </li>
    ); // react-hooks/exhaustive-deps
};

export default QuestionnaireItem;