import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {AsyncContext, AuthContext} from "../context";
import cl from "./styles/Main.module.scss";
import QuestionList from "../UI/QuestionList";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

const QuestionnaireDetail = () => {
    const {id} = useParams();
    const {user} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);
    const navigate = useNavigate();
    const [questionnaire, setQuestionnaire] = useState({});
    const [errors, setErrors] = useState({});
    const [answers, setAnswers] = useState({});
    const [success, setSuccess] = useState(false);

    const setAnswer = (question, answer) => {
        setAnswers((prevState) => {
            let temp = prevState;
            temp[question.id] = answer;
            return temp;
        });
    };

    useEffect(() => {
        if (user && id) {
            request(
                'get',
                `questionnaire/${id}/`,
                {token: user.token},
                (response) => {
                    if (response.status === 200)
                        setQuestionnaire(response.data);
                    else
                        navigate('/');
                }
            );
        }
    }, [user, id]); // eslint-disable-line react-hooks/exhaustive-deps

    const saveAnswers = (event) => {
        event.preventDefault();
        if (Object.entries(answers).some(([k, v]) => {
            return v === '';
        })) {
            setErrors({
                empty_error: 'Не все поля были заполнены'
            });
            return false;
        }
        request(
            'post',
            `questionnaire/${id}/answers`,
            {
                token: user.token,
                answers: answers
            },
            (response) => {
                if (response.status === 200)
                    setSuccess(true);
                else
                    setErrors(response.data.errors);
            }
        );
    };

    const onOk = () => {
        navigate('/');
    }

    return (
        <form onSubmit={saveAnswers}>
            <div className={cl.FullPage}>
                <div className={`card ${cl.Card}`}>
                    <div className='card-header header-secondary'>
                        {questionnaire ? questionnaire.name : ''}
                    </div>
                    {
                        Object.entries(errors || {}).map(([k, v]) => {
                            return (
                                <div key={k} className='alert alert-danger card-body rounded-0 mb-0'>
                                    {v}
                                </div>
                            )
                        })
                    }
                    <QuestionList questionnaire={questionnaire} setAnswer={setAnswer}/>
                    <div className='card-body text-center'>
                        <Button color='primary' className='me-2'>
                            Отправить
                        </Button>
                        <Link to='/'>
                            <Button color='secondary'>
                                Отмена
                            </Button>
                        </Link>
                    </div>
                    <Modal
                        isShown={success}
                        setIsShown={onOk}
                        title={questionnaire.name}
                    >
                        <div className='text-center'>
                            <h3 className='text-success'>
                                Ответы на анкету успешно сохранены!
                            </h3>
                            <Button color='success' onClick={onOk}>
                                Ок
                            </Button>
                        </div>
                    </Modal>
                </div>
            </div>
        </form>
    );
};

export default QuestionnaireDetail;