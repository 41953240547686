import React, {useEffect, useState} from 'react';
import FormChoiceOne from "./FormChoiceOne";
import FormChoiceMulti from "./FormChoiceMulti";

const ChoiceQuestion = ({question, setAnswer, ...props}) => {
    const [value, setValue] = useState(question.has_multiselect ? [] : '');

    useEffect(() => {
        setAnswer(question, question.has_multiselect ? value.join(", ") : value);
    }, [value]);

    return (
        <li className='list-group-item'>
            {
                question.has_multiselect
                    ? <FormChoiceMulti
                        label={question.text}
                        variants={question.variants}
                        name={`question_${question.id}`}
                        value={value}
                        set={setValue}
                    />
                    : <FormChoiceOne
                        label={question.text}
                        variants={question.variants}
                        name={`question_${question.id}`}
                        value={value}
                        set={setValue}
                    />
            }
        </li>
    );
};

export default ChoiceQuestion;