import React from 'react';
import cl from './styles/Modal.module.scss'

const Modal = ({children, title, isShown, setIsShown, errors, ...props}) => {
    return (
        <div className={`${cl.Wrapper} ${isShown ? cl.Show : ''}`}>
            <div className={`card ${cl.Content}`}>
                <div className={`card-header header-secondary ${cl.Header}`}>
                    <h5>{title}</h5>
                    <button type="button" className="btn-close" onClick={() => setIsShown(false)}></button>
                </div>
                {
                    Object.entries(errors || {}).map(([k, v]) => {
                        return (
                            <div key={k} className='alert alert-danger card-body rounded-0'>
                                {v}
                            </div>
                        )
                    })
                }
                <div className="card-body">
                    {children}
                </div>
            </div>
        </div>

    );
};

export default Modal;