import React from 'react';

const FormChoiceMulti = ({label, variants, value, set, name, ...props}) => {
    const handleClick = (text) => {
        return (event) => {
            if (event.target.checked)
                set([...value, text]);
            else
                set([...value.filter(item => item !== text)]);
        }
    };

    return (
        <div>
            <div className='form-group'>
                <label className='form-label'>{label}</label>
                {variants.map((variant) => {
                    return (
                        <div className='form-check' key={variant.id}>
                            <input
                                type='checkbox'
                                name={`variant_${variant.id}`}
                                id={`variant_${variant.id}`}
                                className='form-check-input'
                                onClick={handleClick(variant.text)}
                            />
                            <label
                                htmlFor={`variant_${variant.id}`}
                                className='form-check-label'
                            >
                                {variant.text}
                            </label>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FormChoiceMulti;