import React, {useContext, useEffect, useState} from 'react';
import Icon from "./Icon";
import RegisterForm from "./RegisterForm";
import Modal from "./Modal";
import Button from "./Button";
import {AsyncContext, IdentificationContext} from "../context";

const Register = () => {
    const [isModalShown, setIsModalShown] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errors, setErrors] = useState([]);
    const {request} = useContext(AsyncContext);
    const {identificationMode, setIdentificationMode} = useContext(IdentificationContext);

    useEffect(() => {
        setIsModalShown(identificationMode === 'register');
    }, [identificationMode]);

    const register = (userData) => {
        if (userData !== null) {
            setIdentificationMode('');
            request('post', 'registration/', userData, registerCallback);
        } else
            setIdentificationMode('');
    };

    const registerCallback = (response) => {
        if (response.status === 200) {
            setIsSuccess(true);
        } else {
            setIdentificationMode('register');
            setErrors(response.data.errors);
        }
    };

    return (
        <div className='d-inline'>
            <Button color='primary' className='me-2' onClick={() => setIdentificationMode('register')}>
                <Icon name='person-plus' className='me-2'/>
                <span>Регистрация</span>
            </Button>
            <Modal
                title='Регистрация'
                isShown={isModalShown}
                setIsShown={() => register(null)}
                errors={errors}
            >
                <RegisterForm callback={register}/>
            </Modal>
            {
                !isSuccess ? '' :
                    <Modal
                        title='Регистрация'
                        isShown={isSuccess}
                        setIsShown={setIsSuccess}
                    >
                        <div className='text-center'>
                            <h3 className='text-success'>
                                Для продолжения регистрации пройдите по ссылке отправленной Вам на электронную почту.
                            </h3>
                            <Button className='btn btn-success px-5' onClick={() => setIsSuccess(false)}>
                                Ок
                            </Button>
                        </div>
                    </Modal>
            }
        </div>
    );
};

export default Register;