import React, {useState} from 'react';
import FormTextInput from "./FormTextInput";
import Button from "./Button";

const RegisterForm = ({callback, ...props}) => {
    const [email, setEmail] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');

    const register = (event) => {
        event.preventDefault();
        callback({
            email,
            last_name: lastName,
            first_name: firstName
        })
    };

    return (
        <div>
            <form onSubmit={register}>
                <FormTextInput label='E-Mail' type='email' value={email} set={setEmail} name='email'/>
                <FormTextInput label='Фамилия' type='text' value={lastName} set={setLastName} name='last_name'/>
                <FormTextInput label='Имя' type='text' value={firstName} set={setFirstName} name='first_name'/>
                <div className='form-group d-flex flex-row justify-content-center mt-2'>
                    <Button color='primary' className='flex-grow-1'>
                        Регистрация
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default RegisterForm;