import {useEffect, useState} from "react";
import Moment from "moment";
import {useNavigate} from "react-router-dom";

const useChatTimers = (room) => {
    const [message, setMessage] = useState('');
    const [enabled, setEnabled] = useState(false);
    const navigate = useNavigate();

    const onStart = () => {
        setMessage('Событие началось! Можете начинать общение!');
        setEnabled(true);
    };

    const onFinish = () => {
        setTimeout(() => {
            if (room.need_answers_at_exit)
                navigate(`/questionnaire/${room.exit_questionnaire_id}`);
            else
                navigate('/');
        }, 5000);
        setMessage('Событие завершено! Через несколько секунд Вам будет предложена анкета.');
        setEnabled(false);
    };

    const startTimers = () => {
        const timeToStart = Date.parse(room.start) - Date.now();
        const timeToFinish = Date.parse(room.finish) - Date.now();

        if (timeToStart > 0) {
            setMessage(`До начала события осталось менее ${parseInt(Moment(timeToStart).format('m')) + 1} минут(ы)`);
            setTimeout(() => {
                onStart();
            }, timeToStart);
        } else
            onStart();

        if (timeToFinish > 0)
            setTimeout(() => {
                onFinish();
            }, timeToFinish);
        else {
            onFinish();
        }
    };

    useEffect(() => {
        if (room)
            startTimers();
    }, [room]);

    return [message, enabled, setEnabled];
};

export default useChatTimers;